<script>
import routes from "../router/routes";
import BaseButton, {
  ButtonUITypeEnum,
  ButtonBehaviourEnum,
} from "@/components/Buttons/BaseButton.vue";
export default {
  name: "ReturnButton",
  components: { BaseButton },
  computed: {
    ButtonUITypeEnum() {
      return ButtonUITypeEnum;
    },
    ButtonBehaviourEnum() {
      return ButtonBehaviourEnum;
    },
  },
  data() {
    return {
      showBackButton: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    $route(to, from) {
      const dashboardRoute = routes.find(
        (route) => route.name === "dashboard"
      ).path;
      const feedbackRoute = routes.find(
        (route) => route.name === "feedback"
      ).path;
      this.showBackButton =
        from.name !== null &&
        to.path !== dashboardRoute &&
        to.path !== feedbackRoute;
    },
  },
};
</script>

<template>
  <BaseButton
    class="fixed !top-[105px] !left-2 !px-12 !py-1.5 z-50"
    text="Back"
    :buttonBehaviour="ButtonBehaviourEnum.onClick"
    :buttonUIType="ButtonUITypeEnum.mainButton"
    :onClick="goBack"
  >
    <span
      class="absolute left-2 flex justify-center items-center text-4xl mb-1 font-thin"
      >&lt;</span
    >
  </BaseButton>
</template>

<style scoped></style>
