import { defineStore } from "pinia";
import {
  initializeMsalInstance,
  isMsalInitialized,
  msalInstance,
  tokenRequest,
} from "@/msal/authConfig";
import axios from "axios";
import { getTokenRedirect } from "@/msal/authRedirect";
import { apiConfig } from "@/msal/apiConfig";
import { useProfileDataStore } from "@/stores/ProfileDataStore";
import { currentCustomer } from "@/stores/CustomerMetadataStore";
import { CustomerMetadata } from "@/models/CustomerMetadata";
import signalRService from "@/utils/SignalRService";
import {
  sortAthletesByAge,
  sortAthletesByAssessment,
  sortAthletesByFirstName,
  sortAthletesByLastName,
} from "@/utils/SortUtils";

/**
 * Axios Interceptors
 * These interceptors are used to attach the valid token to the request headers
 * The request interceptor also checks if the token is expired and redirects to login if it is
 * The response interceptor also checks if the response status is 401 and redirects to login if it is
 */
axios.interceptors.request.use(
  async function (config) {
    if (!isMsalInitialized) {
      console.log("msal not initialized, initializing now");
      await initializeMsalInstance();
    }

    const account = msalInstance.getActiveAccount(); // Retrieve the active account from MSAL
    if (!account) {
      // No account is logged in; redirect to login
      console.warn("No active account, redirecting to login...");
      await msalInstance.logoutRedirect();
      return Promise.reject(new Error("No active account"));
    }

    const token = await msalInstance
      .acquireTokenSilent(tokenRequest)
      .then((response) => response.accessToken)
      .catch(() => null);

    if (!token) {
      console.warn("No token available, redirecting to login...");
      await msalInstance.logoutRedirect();
      return Promise.reject(new Error("No token available"));
    }

    // Decode JWT payload manually
    function decodeJwt(token) {
      try {
        const base64Url = token.split(".")[1]; // Get the payload part of the JWT
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
        );
        return JSON.parse(jsonPayload);
      } catch (error) {
        console.error("Error decoding JWT:", error);
        return null;
      }
    }

    const decodedToken = decodeJwt(token);

    if (!decodedToken) {
      console.warn("Unable to decode token, redirecting to login...");
      await msalInstance.logoutRedirect();
      return Promise.reject(new Error("Unable to decode token"));
    }

    // Check if the token is expired
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    if (decodedToken.exp < currentTime) {
      console.warn("Token expired, redirecting to login...");
      await msalInstance.logoutRedirect();
      return Promise.reject(new Error("Token expired"));
    }

    // Attach the valid token to the request headers
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 errors and logout the user if they occur
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      msalInstance
        .logoutRedirect()
        .then(() => {
          console.log("Logged out");
        })
        .catch((e) => {
          console.log("Error logging out", e);
        });
    }
    return Promise.reject(error);
  }
);

export const useDataStore = defineStore("dataStore", {
  state: () => {
    return {
      athletes: [],
      assessments: [],
      graphMetadata: [],
      notes: [],
      assessmentData: {},
      notifications: [],
      exercises: [],
      groups: [],
      timeOfLastNoteFetch: 0,
      timeOfLastAthleteFetch: 0,
      timeOfLastAssessmentFetch: 0,
      timeOfLastCustomerFetch: 0,
      profilePicture: null,
      events: {},
      allCustomers: [],
      currentCustomer: currentCustomer,
      longitudinalData: [],
    };
  },
  actions: {
    // Function to emit events from dataStore
    //How to use : this.emit('Event Name', OptionalData) ...args are optional and can be used send data
    emit(eventName, ...args) {
      if (!this.events[eventName]) return;

      this.events[eventName].forEach((listener) => {
        listener(...args);
      });
    },

    // Function to register event listeners
    //How to use : From any Component where you have a reference from dataStore you can listen to events by using the "on" method
    //example : this.dataStore.on('Event name ', (data) => {});
    on(eventName, callback) {
      if (!this.events[eventName]) {
        this.events[eventName] = [];
      }
      this.events[eventName].push(callback);
    },

    // request pipeline rerun
    async requestPipelineRerun(assessmentId, repCount) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      await axios
        .post(
          apiConfig.apiUrl +
            "/movement-assessment/api/pipelinererunrequest/" +
            assessmentId +
            "/rerun" +
            (repCount != null ? "?repCount=" + repCount : ""),
          {},
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (response) => {
          if (response.status === 200) {
            this.createNotification("Rerunning the analysis", "Info");
            this.emit("rerun requested");
          } else {
            this.createNotification("Error requesting the reanalysis", "Error");
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.createNotification(
              "Reanalysis is already in progress. Please await for completion.",
              "Warning"
            );
          } else {
            console.log(e);
            this.createNotification("Error requesting the reanalysis", "Error");
          }
        });
    },

    //Assessment data to populate visualizations on Assessment's page
    async fetchAssessmentDataFile(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .get(
            apiConfig.apiUrl +
              "/data-visualisation/api/vizdata/getByAssessmentId?assessmentId=" +
              id,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            this.assessmentData = response.data;
            resolve(response.data);
          })
          .catch((e) => {
            this.assessmentData = {};
            console.warn(e);
            this.assessmentData.error = "Data not available";
            if (e.response.status === 404) {
              resolve({ Error: e.response.status });
            } else {
              console.log(e);
              reject(e.response.status);
            }
          });
      });
    },

    async negotiateSignalRConnection() {
      try {
        const connection = await signalRService.initialiseConnection();

        connection.on("ReceiveNotification", (type, message) => {
          this.createNotification(message, type);
        });
      } catch (error) {
        console.error("Failed to establish SignalR connection:", error);
      }
    },

    async fetchAllAthletes() {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      if (
        this.timeOfLastAthleteFetch + 1000 * process.env.VUE_APP_REFRESH_TIME <
        Date.now()
      ) {
        return new Promise((resolve, reject) => {
          axios
            .get(apiConfig.apiUrl + "/movement-assessment/api/athlete", {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              response.data.map((athlete) => {
                if (
                  this.athletes.find(
                    (ath) => ath.athleteId === athlete.athleteId
                  ) === undefined
                )
                  this.athletes.push(athlete);
                else {
                  const athleteIndex = this.athletes.findIndex(
                    (ath) => ath.athleteId === athlete.athleteId
                  );
                  this.athletes[athleteIndex] = athlete;
                }
              });
              resolve(response.data);
            })
            .catch((reason) => {
              console.log(reason);
              reject(reason);
            });
          this.timeOfLastAthleteFetch = Date.now();
        });
      }
    },

    async fetchAthleteData(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve) => {
        axios
          .get(apiConfig.apiUrl + "/movement-assessment/api/athlete/" + id, {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          })
          .then(async (response) => {
            resolve(response.data);
          })
          .catch((reason) => {
            console.log(reason);
            this.createNotification("Error fetching athlete data", "Error");
          });
      });
    },

    async fetchAssessments(
      page = 0,
      pageSize = 20,
      athlete = null,
      sortOrder = "desc",
      groupFilter = null,
      exerciseFilter = null,
      startDate = null,
      endDate = null
    ) {
      await this.fetchExercisesIncludeOpposites();
      const token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(
            apiConfig.apiUrl +
              "/movement-assessment/api/assessment/all?" +
              "page=" +
              page +
              "&pageSize=" +
              pageSize +
              "&sortOrder=" +
              sortOrder +
              (athlete !== null ? "&athleteId=" + athlete : "") +
              (groupFilter !== null ? "&groupFilter=" + groupFilter : "") +
              (exerciseFilter !== null
                ? "&exerciseFilter=" + exerciseFilter
                : "") +
              (startDate !== null ? "&startDate=" + startDate : "") +
              (endDate !== null ? "&endDate=" + endDate : ""),
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            resolve(this.assessmentArrayToAssessmentGlobal(response));
          })
          .catch((reason) => {
            console.log(reason);
            this.createNotification("Error fetching assessments", "Error");
            reject(reason);
          });
      });
    },

    async fetchRecentAthleteAssessments() {
      if (this.exercises.length === 0) {
        await this.fetchExercisesIncludeOpposites();
      }
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      await axios
        .get(apiConfig.apiUrl + "/movement-assessment/api/assessment/athlete", {
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        })
        .then(this.assessmentArrayToAssessmentGlobal)
        .catch((reason) => {
          console.log(reason);
          this.createNotification("Error fetching recent assessments", "Error");
        });
      this.emit("fetchedAssessments");
    },

    async fetchAllAssessmentsForAthlete(athleteId, exerciseFilter = null) {
      if (this.exercises.length === 0) {
        await this.fetchExercisesIncludeOpposites();
      }
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve) => {
        axios
          .get(
            apiConfig.apiUrl +
              "/movement-assessment/api/assessment/athlete/" +
              athleteId +
              (exerciseFilter !== null
                ? "?&exerciseFilter=" + exerciseFilter
                : ""),
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            resolve(this.assessmentArrayToAssessmentGlobal(response));
          })
          .catch((reason) => {
            console.log(reason);
            this.createNotification(
              "Error fetching assessments for an athlete " + athleteId,
              "Error"
            );
          });
      });
    },

    async fetchAssessmentData(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      await axios
        .get(apiConfig.apiUrl + "/movement-assessment/api/assessment/" + id, {
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        })
        .then(async (response) => {
          if (
            this.assessments.find(
              (assessment) => assessment.assessmentId === id
            ) === undefined
          ) {
            if (response.data.exerciseId !== undefined) {
              let exercise = this.exercises.find(
                (exercise) => exercise.exerciseId === response.data.exerciseId
              );
              if (exercise !== undefined) {
                response.data.exerciseName = exercise.name;
                response.data.isExerciseBeta = exercise.beta;
              } else {
                this.fetchExerciseData(response.data.exerciseId).then(
                  (exercise) => {
                    response.data.exerciseName = exercise.name;
                    if (exercise.hasOpposite) {
                      response.data.exerciseName +=
                        " (" + exercise.exerciseSide.toLowerCase() + ")";
                    }
                    response.data.isExerciseBeta = exercise.beta;
                  }
                );
              }
            }
            this.assessments.push(response.data);
          } else {
            const assessmentIndex = this.assessments.findIndex(
              (assessment) => assessment.assessmentId === id
            );
            this.assessments[assessmentIndex].assessmentId =
              response.data.assessmentId;
            this.assessments[assessmentIndex].athleteId =
              response.data.athleteId;
            this.assessments[assessmentIndex].exerciseId =
              response.data.exerciseId;
            this.assessments[assessmentIndex].timestamp =
              response.data.timestamp;
            this.assessments[assessmentIndex].csvData = response.data.csvData;
            this.assessments[assessmentIndex].videoUrl = response.data.videoUrl;
            this.assessments[assessmentIndex].staticAngle =
              response.data.staticAngle;
            this.assessments[assessmentIndex].mobilityMaxRom =
              response.data.mobilityMaxRom;

            const exercise = this.exercises.find(
              (exercise) => exercise.exerciseId === response.data.exerciseId
            );
            if (exercise !== undefined) {
              this.assessments[assessmentIndex].exerciseName = exercise.name;
              if (exercise.hasOpposite) {
                this.assessments[assessmentIndex].exerciseName +=
                  " (" + exercise.exerciseSide.toLowerCase() + ")";
              }
              this.assessments[assessmentIndex].isExerciseBeta = exercise.beta;
            }
          }
        })
        .catch((reason) => {
          console.log(reason);
          this.createNotification("Error fetching assessment data", "Error");
        });
    },

    /**
     * Send request to API to update an assessment
     * @param assessment body of updated assessment (updated exercise and athlete ids)
     * @returns {Promise<*>} returns 200 if successful or Error status otherwise
     */
    async updateAssessmentData(assessment) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      let res;
      await axios
        .put(
          apiConfig.apiUrl +
            "/movement-assessment/api/assessment/" +
            assessment.assessmentId,
          {
            assessmentId: assessment.assessmentId,
            timestamp: assessment.timestamp,
            exerciseId: assessment.exerciseId,
            athleteId: assessment.athleteId,
            repetitions: assessment.repetitions,
          },
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.createNotification("Assessment updated", "success");
          res = 200;
          this.assessments[assessment.assessmentId] = assessment;
        })
        .catch((reason) => {
          this.createNotification("Error updating assessment", "Error");
          res = reason.status;
        });

      return res;
    },

    async fetchExerciseUniqueAssessmentData(athleteId) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      await axios
        .get(
          apiConfig.apiUrl +
            "/movement-assessment/api/assessment/athlete/" +
            athleteId +
            "/uniqueExercises",
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.assessments = this.assessments
            .concat(response.data)
            .reduce((acc, assessment) => {
              const index = acc.findIndex(
                (a) => a.assessmentId === assessment.assessmentId
              );
              if (index === -1) {
                acc.push(assessment);
              } else {
                acc[index] = assessment;
              }
              return acc;
            }, []);
        })
        .catch((reason) => {
          console.log(reason);
          this.createNotification(
            "Error fetching unique assessment data",
            "Error"
          );
        });
    },

    async hideAssessment(assessmentId) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      let res;

      await axios
        .put(
          apiConfig.apiUrl +
            "/movement-assessment/api/assessment/hideAssessment/" +
            assessmentId,
          {},
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.createNotification("Assessment deleted", "success");
          res = 200;
          this.assessments.splice(
            this.assessments.find(
              (assessment) => assessment.assessmentId === assessmentId
            ),
            1
          );
        })
        .catch((reason) => {
          this.createNotification("Error deleting assessment", "Error");
          console.log(reason);
          res = reason.status;
        });

      return res;
    },

    /**
     * Assessment Notes Methods
     */
    async createAssessmentNote(note) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .post(apiConfig.apiUrl + "/movement-assessment/api/notes", note, {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.notes.push(response.data);
            resolve(response.data);
          })
          .catch((e) => {
            this.createNotification(
              "Error creating note for assessment",
              "Error"
            );
            reject(e);
          });
      });
    },

    async fetchNoteByAssessmentId(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve) => {
        axios
          .get(
            apiConfig.apiUrl +
              "/movement-assessment/api/notes/getByAssessment/" +
              id,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              resolve(null); // Return null for 404 errors
            } else {
              this.createNotification("Error fetching note data", "Error");
            }
          });
      });
    },

    async fetchAllNotesByAthleteId(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(
            apiConfig.apiUrl +
              "/movement-assessment/api/notes/getByAthlete/" +
              id,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) =>
            resolve(this.notesArrayToAssessmentGlobal(response))
          )
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              resolve(null); // Return null for 404 errors
            } else {
              this.createNotification("Error fetching note data", "Error");
              reject(error);
            }
          });
      });
    },

    /**
     * Send request to API to update a note
     * @param note body of updated note (updated note content andpain level)
     * @returns {Promise<*>} returns 200 if successful or Error status otherwise
     */
    async updateAssessmentNoteData(note) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .put(
            apiConfig.apiUrl + "/movement-assessment/api/notes/" + note.noteId,
            {
              noteContent: note.noteContent,
              painLevel: note.painLevel,
            },
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            resolve(200);
          })
          .catch((error) => {
            this.createNotification("Error updating note", "Error");
            reject(error.status);
          });
      });
    },

    async deleteAssessmentNote(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .delete(apiConfig.apiUrl + "/movement-assessment/api/notes/" + id, {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            this.createNotification("Note deleted", "Info");
            resolve(200);
          })
          .catch((error) => {
            this.createNotification(
              "Error deleting note with reason: " + error,
              "Error"
            );
            reject(error.status);
          });
      });
    },

    async registerAthlete(athlete) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .post(
            apiConfig.apiUrl + "/movement-assessment/api/athlete",
            athlete,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // if response data doesn't contain groups, add an empty array
            if (!response.data.groups) {
              response.data.groups = [];
            }

            this.athletes.push(response.data);
            this.createNotification(
              "Athlete " +
                athlete.firstName +
                " " +
                (athlete.lastName || "") +
                " created",
              "success"
            );

            resolve(response.data);
          })
          .catch((e) => {
            this.createNotification(
              "Error creating athlete " +
                athlete.firstName +
                " " +
                (athlete.lastName || ""),
              "Error"
            );
            reject(e);
          });
      });
    },

    async updateAthleteGroups(athleteId, groups) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) =>
        axios
          .put(
            apiConfig.apiUrl +
              "/movement-assessment/api/athlete/" +
              athleteId +
              "/group",
            groups,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.athletes.find(
              (athlete) => athlete.athleteId === Number(athleteId)
            ).groups = response.data;
            resolve(response.data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          })
      );
    },

    async deleteGroupFromAthlete(athleteId, groupId) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      await axios
        .delete(
          apiConfig.apiUrl +
            "/movement-assessment/api/athlete/" +
            athleteId +
            "/group/" +
            groupId,
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.athletes.find(
            (athlete) => athlete.athleteId === Number(athleteId)
          ).groups = this.athletes
            .find((athlete) => athlete.athleteId === Number(athleteId))
            .groups.filter((group) => group.groupId !== groupId);
        })
        .catch((reason) => {
          this.createNotification(
            "Error deleting group from athlete with reason: " + reason,
            "Error"
          );
        });
    },

    async updateAthlete(inputAthlete) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .put(
            apiConfig.apiUrl +
              "/movement-assessment/api/athlete/" +
              inputAthlete.athleteId,
            {
              athleteId: inputAthlete.athleteId,
              firstName: inputAthlete.firstName,
              lastName: inputAthlete.lastName,
              dob: inputAthlete.dob,
              bodyweight: inputAthlete.bodyweight,
              height: inputAthlete.height,
              gender: inputAthlete.gender,
              hideFace: inputAthlete.hideFace,
            },
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            let updatedAthlete = this.athletes.find(
              (athlete) => athlete.athleteId === response.data.athleteId
            );
            updatedAthlete.firstName = response.data.firstName;
            updatedAthlete.lastName = response.data.lastName;
            updatedAthlete.dob = response.data.dob;
            updatedAthlete.bodyweight = response.data.bodyweight;
            updatedAthlete.gender = response.data.gender;
            updatedAthlete.height = response.data.height;
            resolve(response.data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    async deleteAthlete(athlete) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      const athleteData = this.athletes.find((a) => a.athleteId === athlete);

      await axios
        .delete(
          apiConfig.apiUrl + "/movement-assessment/api/athlete/" + athlete,
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.athletes = this.athletes.filter((a) => a.athleteId !== athlete);
          this.createNotification(
            "Athlete " +
              athleteData.firstName +
              " " +
              (athleteData.lastName || " ") +
              " deleted",
            "success"
          );
        })
        .catch(() => {
          this.createNotification(
            "Error deleting athlete " +
              athleteData.firstName +
              " " +
              (athleteData.lastName || " "),
            "Error"
          );
        });
    },

    async registerGroup(group) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      await axios
        .post(apiConfig.apiUrl + "/movement-assessment/api/group", group, {
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.groups.push(response.data);
          this.createNotification(
            "Group " + response.data.name + " created",
            "success"
          );
        })
        .catch(() => {
          this.createNotification(
            "Error creating group " + group.name,
            "Error"
          );
        });
    },

    async registerGroupForAthlete(athleteId, group) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      await axios
        .post(
          apiConfig.apiUrl +
            "/movement-assessment/api/athlete/" +
            athleteId +
            "/group/" +
            group.groupId,
          {
            athleteId: athleteId,
            groupId: group.groupId,
          },
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          try {
            const athlete = this.athletes.find(
              (athlete) => athlete.athleteId === Number(athleteId)
            );

            if (!athlete.groups) {
              athlete.groups = []; // Initialize groups as an empty array if it doesn't exist
            }

            athlete.groups.push(group);
            this.emit("athleteAddedToGroup");
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e) => {
          console.log(e);
          this.createNotification(
            e + `athlete id ${athleteId} group id ${group.groupId}`,
            "Error"
          );
          this.createNotification("Error adding to group", "Error");
        });
    },

    async fetchAllGroups() {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      await axios
        .get(apiConfig.apiUrl + "/movement-assessment/api/group", {
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.groups = response.data;
          this.sortGroupsByName();
          this.emit("fetchedGroups");
        })
        .catch((e) => {
          console.log(e);
          this.createNotification("Error fetching groups", "Error");
        });
    },

    async updateGroup(group) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .put(
            apiConfig.apiUrl +
              "/movement-assessment/api/group/" +
              group.groupId,
            group,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            resolve(Number(response.status));
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    async deleteGroup(group) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .delete(
            apiConfig.apiUrl +
              "/movement-assessment/api/group/" +
              group.groupId,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            resolve(Number(response.status));
          })
          .catch((reason) => {
            console.log(reason);
            reject(reason);
          });
      });
    },

    async uploadProfilePicture(image) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      const formData = new FormData();
      formData.append("file", image);

      await axios
        .post(apiConfig.apiUrl + "/movement-assessment/api/profile", formData, {
          headers: {
            Authorization: bearer,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.createNotification("Profile picture uploaded", "Info");
            useProfileDataStore().updateProfileString();
          } else {
            this.createNotification("Error uploading profile picture", "Error");
          }
        })
        .catch(() => {
          this.createNotification("Error uploading profile picture", "Error");
        });
    },

    /**
     * CUSTOMER FEEDBACK
     */

    /**
     * Uploads a feedback and screenshots to the server
     * @param formData - The form data containing the feedback and the screenshots {feedback: {email: "", content: ""}, screenshots: []}
     * @returns {Promise<axios.AxiosResponse<any>>} - The response from the server
     */
    async sendEmailToSupport(formData) {
      try {
        let token = await getTokenRedirect(tokenRequest);
        const bearer = `Bearer ${token}`;
        return await axios.post(
          apiConfig.apiUrl + "/movement-assessment/api/feedback/send-email",
          formData,
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "multipart/form-data", // Set the correct content type
            },
          }
        );
      } catch (e) {
        throw new Error("Error sending email");
      }
    },

    /**
     * CUSTOMER DATA
     */

    async fetchCustomerTierMetadata() {
      await msalInstance.acquireTokenSilent(tokenRequest);
      const activeAccount = msalInstance.getActiveAccount();
      if (
        activeAccount === undefined ||
        null ||
        !activeAccount?.idTokenClaims
      ) {
        msalInstance.logoutRedirect().catch((e) => {
          console.log("Error logging out", e);
        });
        return null;
      }
      const token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      let organisation = activeAccount.idTokenClaims.extension_Team;
      let fullName =
        activeAccount.idTokenClaims.given_name +
        " " +
        msalInstance.getActiveAccount().idTokenClaims.family_name;
      let email = activeAccount.idTokenClaims.emails[0];
      let measurementSystem =
        activeAccount.idTokenClaims?.extension_MeasurementSystem;

      return axios
        .post(
          apiConfig.apiUrl + "/customer-tier/api/customer/me",
          {
            organisation: organisation,
            fullName: fullName,
            email: email,
            measurementSystem: measurementSystem,
          },
          {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.currentCustomer.setData(response.data);
          this.emit("customerData");
        })
        .catch((reason) => {
          console.log(reason);
          this.createNotification("Error fetching your data", "Error");
        });
    },

    async fetchAllCustomersMetadata(
      sortType = "expiration",
      page = 0,
      pageSize = 20,
      sortOrder = "desc",
      searchText = "",
      tier = -1
    ) {
      const token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(
            apiConfig.apiUrl +
              "/customer-tier/api/customer/all?" +
              "pageSize=" +
              pageSize +
              "&page=" +
              page +
              "&sortType=" +
              sortType +
              "&sortOrder=" +
              sortOrder +
              "&searchText=" +
              searchText +
              "&tier=" +
              tier,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (page === 0) {
              this.allCustomers = [];
            }
            this.emit("responseLength", response.data.length);
            response.data.forEach((customerResponse) => {
              let customer = new CustomerMetadata();
              customer.setData(customerResponse);
              if (
                this.allCustomers.find(
                  (cus) => cus.customerId === customer.customerId
                ) === undefined
              )
                this.allCustomers.push(customer);
              else {
                const customerIndex = this.allCustomers.findIndex(
                  (cus) => cus.customerId === customer.customerId
                );
                this.allCustomers[customerIndex] = customer;
              }
            });
            resolve(this.allCustomers);
          })
          .catch((reason) => {
            console.log(reason);
            this.createNotification("Error fetching customers data", "Error");
            reject(reason);
          });
      });
    },

    async updateCustomerMetadata(customerId, updatedData) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .put(
            apiConfig.apiUrl + "/customer-tier/api/customer/" + customerId,
            updatedData,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            let updatedCustomer = this.allCustomers.find(
              (cus) => cus.customerId === customerId
            );
            updatedCustomer.expirationDate = response.data.expirationDate;
            updatedCustomer.tier = response.data.tier;
            updatedCustomer.comments = response.data.comments;
            resolve(true);
          })
          .catch((reason) => {
            console.log(reason);
            reject(reason);
          });
      });
    },

    /**
     * EXERCISE REQUESTS
     */

    async fetchExercisesIncludeOpposites() {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(
            apiConfig.apiUrl +
              "/custom-exercise/api/exercise/include-opposites",
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // Merge exercises: keep existing exercises and add new ones
            this.exercises = this.exercises
              .concat(response.data)
              .reduce((acc, exercise) => {
                const existingExerciseIndex = acc.findIndex(
                  (e) => e.exerciseId === exercise.exerciseId
                );
                if (existingExerciseIndex === -1) {
                  acc.push(exercise);
                } else {
                  // Update existing exercise with new data
                  acc[existingExerciseIndex] = exercise;
                }
                return acc;
              }, []);

            // Add exercise name to each exercise
            this.exercises.map((exercise) => {
              exercise.exerciseName = exercise.name;
              if (exercise.hasOpposite) {
                exercise.exerciseName +=
                  " (" + exercise.exerciseSide.toLowerCase() + ")";
              }
            });

            // Sort by exercise name
            this.exercises.sort((a, b) =>
              a.exerciseName.toLowerCase() > b.exerciseName.toLowerCase()
                ? 1
                : -1
            );

            resolve(response.data);
          })
          .catch((reason) => {
            this.createNotification("Error fetching exercises", "Error");
            console.log(reason);
            reject(reason);
          });
      });
    },

    async fetchExercises() {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(apiConfig.apiUrl + "/custom-exercise/api/exercise", {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.exercises = this.exercises
              .concat(response.data)
              .reduce((acc, exercise) => {
                const existingExerciseIndex = acc.findIndex(
                  (e) => e.exerciseId === exercise.exerciseId
                );
                if (existingExerciseIndex === -1) {
                  acc.push(exercise);
                } else {
                  // Update existing exercise with new data
                  acc[existingExerciseIndex] = exercise;
                }
                return acc;
              }, []);
            resolve(response.data);
          })
          .catch((reason) => {
            this.createNotification("Error fetching exercises", "Error");
            console.log(reason);
            reject(reason);
          });
      });
    },

    async fetchExerciseData(id) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .get(apiConfig.apiUrl + "/custom-exercise/api/exercise/" + id, {
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            response.data.exerciseName = response.data.name;
            if (response.data.hasOpposite) {
              response.data.exerciseName +=
                " (" + response.data.exerciseSide.toLowerCase() + ")";
            }

            // if exercise exists, update it
            if (
              this.exercises.find(
                (ex) => ex.exerciseId === response.data.exerciseId
              ) !== undefined
            ) {
              const exerciseIndex = this.exercises.findIndex(
                (ex) => ex.exerciseId === response.data.exerciseId
              );
              this.exercises[exerciseIndex] = response.data;
            } else {
              // if exercise does not exist, add it
              this.exercises.push(response.data);
            }

            resolve(response.data);
          })
          .catch((reason) => {
            this.createNotification("Error fetching exercise data", "Error");
            console.log(reason);
            reject(reason);
          });
      });
    },

    async createExercise(exercise) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .post(
            apiConfig.apiUrl +
              "/custom-exercise/api/exercise/module/" +
              exercise.module.toLowerCase(),
            exercise,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.exercises.push(response.data);
            resolve(response.data);
          })
          .catch((reason) => {
            this.createNotification(
              "Unable to create exercise. Please try again",
              "Error"
            );
            console.log(reason);
            reject(reason);
          });
      });
    },

    async updateExercise(exercise) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .put(
            apiConfig.apiUrl +
              "/custom-exercise/api/exercise/module/" +
              exercise.module.toLowerCase() +
              "/" +
              exercise.exerciseId,
            exercise,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // if exercise exists, update it
            if (
              this.exercises.find(
                (ex) => ex.exerciseId === response.data.exerciseId
              ) !== undefined
            ) {
              const exerciseIndex = this.exercises.findIndex(
                (ex) => ex.exerciseId === response.data.exerciseId
              );
              this.exercises[exerciseIndex] = response.data;
            } else {
              // if exercise does not exist, add it
              this.exercises.push(response.data);
            }
            resolve(response.data);
          })
          .catch((reason) => {
            this.createNotification(
              "Unable to update exercise. Please try again",
              "Error"
            );
            console.log(reason);
            reject(reason);
          });
      });
    },

    async deleteExercise(exerciseId) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios
          .delete(
            apiConfig.apiUrl + "/custom-exercise/api/exercise/" + exerciseId,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            this.exercises = this.exercises.filter(
              (exercise) => exercise.exerciseId !== exerciseId
            );
            resolve(true);
          })
          .catch((reason) => {
            this.createNotification(
              "Unable to delete exercise. Please try again",
              "Error"
            );
            console.log(reason);
            reject(reason);
          });
      });
    },

    /**
     * Longitudinal Data
     */

    /**
     * Fetch graphs and the associated data for an athlete
     * @param {number} athleteId - The athlete id
     * @param {string} startDate - The start date
     * @param {string} endDate - The end date
     * @returns {Promise<unknown>}
     */
    async fetchGraphMetadataAndData(
      athleteId,
      startDate = null,
      endDate = null
    ) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      const params = new URLSearchParams();

      if (startDate) {
        params.append("startDate", startDate);
      }
      if (endDate) {
        params.append("endDate", endDate);
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${
              apiConfig.apiUrl
            }/movement-assessment/api/results/graph/${athleteId}/data?${params.toString()}`,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.graphs = response.data.metadata;
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            this.createNotification(
              "Error fetching graph metadata and data",
              "Error"
            );
            reject(error);
          });
      });
    },

    async fetchExerciseGraphMetadataAndData(
      athleteId,
      exerciseId,
      startDate = null,
      endDate = null
    ) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      const params = new URLSearchParams();
      if (startDate) {
        params.append("startDate", startDate);
      }
      if (endDate) {
        params.append("endDate", endDate);
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${
              apiConfig.apiUrl
            }/movement-assessment/api/results/graph/${athleteId}/${exerciseId}/data?${params.toString()}`,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.graphs = response.data.metadata;
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            this.createNotification(
              "Error fetching graph metadata and data",
              "Error"
            );
            reject(error);
          });
      });
    },

    /**
     * Fetches the graph data for a specific graph
     * @param {number} athleteId - The athlete id
     * @param {number} exerciseId - The exercise id
     * @param {string[]} metrics - Array of metrics to be included in the graph
     * @param {boolean} shownOnAthlete - Whether the graph is shown on the athlete's profile
     * @param {boolean} shownOnExercise - Whether the graph is shown on the exercise's profile
     * @param {number[]} joints - Array of joint ids to be included in the graph
     * @returns {Promise<unknown>} - The response from the server
     */
    async createGraph(
      athleteId,
      exerciseId,
      metrics,
      shownOnAthlete,
      shownOnExercise,
      joints = null
    ) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      const graphData = {
        athleteId: athleteId,
        exerciseId: exerciseId,
        metrics: metrics,
        aggregation: 0,
        shownOnAthlete: shownOnAthlete,
        shownOnExercise: shownOnExercise,
        joints: joints,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            apiConfig.apiUrl + "/movement-assessment/api/results/graph",
            graphData,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 201) {
              resolve(response.data);
            } else {
              this.createNotification("Error creating graph", "Error");
              reject(response.status);
            }
          })
          .catch((error) => {
            this.createNotification("Error creating graph", "Error");
            console.log(error);
            reject(error);
          });
      });
    },

    /**
     * Creates or updates a graph for an athlete.
     *
     * @param {number} graphId - The unique identifier for the graph.
     * @param {number} athleteId - The unique identifier for the athlete.
     * @param {number} exerciseId - The unique identifier for the exercise.
     * @param {string[]} metrics - Array of metrics to be included in the graph.
     * @param {boolean} shownOnAthlete - Whether the graph is shown on the athlete's profile.
     * @param {boolean} shownOnExercise - Whether the graph is shown on the exercise's profile.
     * @param {number[]} joints - Array of joint ids to be included in the graph.
     * @returns {Promise<unknown>} - The response from the server.
     */
    async updateGraph(
      graphId,
      athleteId,
      exerciseId,
      metrics,
      shownOnAthlete,
      shownOnExercise,
      joints = null
    ) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      const graphData = {
        athleteId: athleteId,
        exerciseId: exerciseId,
        metrics: metrics,
        aggregation: 0,
        shownOnAthlete: shownOnAthlete,
        shownOnExercise: shownOnExercise,
        joints: joints,
      };

      return new Promise((resolve, reject) => {
        axios
          .put(
            `${apiConfig.apiUrl}/movement-assessment/api/results/graph/${graphId}`,
            graphData,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              this.createNotification("Error updating graph", "Error");
              reject(response.status);
            }
          })
          .catch((error) => {
            this.createNotification("Error updating graph", "Error");
            console.log(error);
            reject(error);
          });
      });
    },

    async deleteGraph(graphId) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${apiConfig.apiUrl}/movement-assessment/api/results/graph/${graphId}`,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              this.createNotification("Error deleting graph", "Error");
              reject(response.status);
            }
          })
          .catch((error) => {
            this.createNotification("Error deleting graph", "Error");
            console.log(error);
            reject(error);
          });
      });
    },

    /**
     * Updates the order of the graphs for an athlete.
     * @param athleteId - The unique identifier for the athlete.
     * @param graphOrder - The id of the graphs in the new order
     * @returns {Promise<unknown>}
     */
    async updateAthleteGraphOrder(athleteId, graphOrder) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .put(
            `${apiConfig.apiUrl}/movement-assessment/api/results/graphs/athlete/${athleteId}/order`,
            graphOrder,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              this.createNotification("Error updating graph order", "Error");
              reject(response.status);
            }
          })
          .catch((error) => {
            this.createNotification("Error updating graph order", "Error");
            console.log(error);
            reject(error);
          });
      });
    },

    async updateExerciseGraphOrder(athleteId, exerciseId, graphOrder) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios
          .put(
            `${apiConfig.apiUrl}/movement-assessment/api/results/graphs/athlete/${athleteId}/exercise/${exerciseId}/order`,
            graphOrder,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              this.createNotification("Error updating graph order", "Error");
              reject(response.status);
            }
          })
          .catch((error) => {
            this.createNotification("Error updating graph order", "Error");
            console.log(error);
            reject(error);
          });
      });
    },

    /**
     * Fetches the graph data for a specific exercise and athlete within a date range.
     *
     * @param {number} athleteId - The athlete id.
     * @param {number} exerciseId - The exercise id.
     * @param {string[]} metrics - Array of metrics to be included in the graph.
     * @param {string} startDate - The start date.
     * @param {string} endDate - The end date.
     * @param {number[]} joints - Array of joint ids to be included in the graph.
     * @returns {Promise<unknown>} - The response from the server.
     */
    async getMetrics(
      athleteId,
      exerciseId,
      metrics,
      startDate,
      endDate,
      joints = null
    ) {
      let token = await getTokenRedirect(tokenRequest);
      const bearer = `Bearer ${token}`;
      const params = new URLSearchParams();
      if (startDate) {
        params.append("startDate", startDate);
      }
      if (endDate) {
        params.append("endDate", endDate);
      }

      metrics.split(",").forEach((metric) => {
        params.append("metrics", metric);
      });

      if (joints) {
        joints.forEach((joint) => {
          if (typeof joint === "number") {
            params.append("joints", joint.toString());
          }
        });
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${
              apiConfig.apiUrl
            }/movement-assessment/api/results/metric/${athleteId}/${exerciseId}?${params.toString()}`,
            {
              headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            } else {
              this.createNotification("Error fetching metric data", "Error");
              reject(response.status);
            }
          })
          .catch((error) => {
            this.createNotification("Error fetching metric data", "Error");
            console.log(error);
            reject(error);
          });
      });
    },

    //Sorting algorithms----------------------------------------------------

    sortGroupsByName() {
      if (!this.groups) return;
      this.groups.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    },

    sortGroupsByMembers() {
      this.groups.sort((a, b) => {
        let g1 = this.athletes.filter((athlete) =>
          athlete.groups
            ? athlete.groups.map((group) => group.groupId).includes(a.groupId)
            : false
        );
        let g2 = this.athletes.filter((athlete) =>
          athlete.groups
            ? athlete.groups.map((group) => group.groupId).includes(b.groupId)
            : false
        );

        if (g1.length > g2.length) {
          return -1;
        }
        if (g1.length < g2.length) {
          return 1;
        }
        return 0;
      });
    },

    sortAthletesByAssessment() {
      this.athletes = sortAthletesByAssessment(this.athletes, this.assessments);
    },

    sortAthletesByAge() {
      this.athletes = sortAthletesByAge(this.athletes);
    },

    sortAthletesByFirstName() {
      this.athletes = sortAthletesByFirstName(this.athletes);
    },

    sortAthletesByLastName() {
      this.athletes = sortAthletesByLastName(this.athletes);
    },

    createNotification(message, type) {
      const notification = { message: message, type: type };
      this.notifications.push(notification);
      setTimeout(() => {
        this.notifications.splice(this.notifications.indexOf(notification), 1);
      }, 6000);
    },

    assessmentArrayToAssessmentGlobal(assessmentArray) {
      let tempAssessment = [];
      if (assessmentArray.data.content)
        tempAssessment = assessmentArray.data.content;
      else tempAssessment = assessmentArray.data;
      tempAssessment.map((assessment) => {
        // Add exercise name to assessment
        const exercise = this.exercises.find(
          (exercise) => exercise.exerciseId === assessment.exerciseId
        );

        if (exercise != null) {
          assessment.exerciseName = exercise.name;
          if (exercise.hasOpposite) {
            assessment.exerciseName +=
              " (" + exercise.exerciseSide.toLowerCase() + ")";
          }
          assessment.isExerciseBeta = exercise.beta;
        }
        // find the index of the assessment in the global array
        const assessmentIndex = this.assessments.findIndex(
          (assess) => assess.assessmentId === assessment.assessmentId
        );

        // if the assessment is not in the global array, add it to the array
        if (assessmentIndex == null || assessmentIndex === -1) {
          this.assessments.push(assessment);
        } else {
          // if the assessment is in the global array, update it
          this.assessments[assessmentIndex] = assessment;
        }

        if (assessment.note) this.notes.push(assessment.note);
      });

      return tempAssessment;
    },

    notesArrayToAssessmentGlobal(notesArray) {
      let tempNote = notesArray.data;
      tempNote.map((note) => {
        if (this.notes.find((n) => n.noteId === note.noteId) === undefined) {
          this.notes.push(note);
        } else {
          const noteIndex = this.notes.findIndex(
            (n) => n.noteId === note.noteId
          );
          this.notes[noteIndex].noteId = note.noteId;
          this.notes[noteIndex].assessmentId = note.assessmentId;
          this.notes[noteIndex].editedOn = note.editedOn;
        }
      });
      return tempNote;
    },

    getExerciseUniqueAssessmentsWithExercises(athleteId) {
      return this.assessments
        .filter((assessment) => assessment.athleteId === athleteId)
        .filter(
          (assessment, index, self) =>
            index ===
            self.findIndex((t) => t.exerciseId === assessment.exerciseId)
        )
        .map((assessment) => ({
          ...assessment,
          exercise: this.exercises.find(
            (exercise) => exercise.exerciseId === assessment.exerciseId
          ),
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
    },
  },
  getters: {
    getAssessmentsByAthlete: (state) => {
      return (Id) =>
        state.assessments.filter(
          (assessment) =>
            assessment.athleteId === parseInt(Id) && !assessment.isHidden
        ) || [];
    },
    getAssessment: (state) => {
      return (Id) =>
        state.assessments.find(
          (assessment) => assessment.assessmentId === parseInt(Id)
        ) || {};
    },
    getAthlete: (state) => {
      return (Id) =>
        state.athletes.find(
          (athlete) => athlete.athleteId === parseInt(Id)
        ) || {
          name: "",
          dob: "",
          bodyweight: "",
        };
    },
    getTimeOfLastAthleteUpdate: (state) => {
      return state.timeOfLastAthleteUpdated;
    },
    getTimeOfLastAthleteCreate: (state) => {
      return state.timeOfLastAthleteCreated;
    },
  },
});
