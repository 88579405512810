import * as msal from "@azure/msal-browser";
import {msalInstance, loginRequest, msalConfig, isMsalInitialized} from "@/msal/authConfig";
import { b2cPolicies } from "@/msal/b2cPolicies";
import { datadogRum } from "@datadog/browser-rum";
import * as authConfig from "@/msal/authConfig";
import { initializeMsalInstance } from "@/msal/authConfig";


(async () => {
   await initializeMsalInstance();

  msalInstance
    .handleRedirectPromise()
    .then((response) => {
      if (response) {
        if (
          response.idTokenClaims["tfp"].toUpperCase() ===
          b2cPolicies.names.signUpSignIn.toUpperCase()
        ) {
          handleResponse(response);
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });

  // in case of page refresh
  selectAccount();
})();

let accountId = "";
let username = "";
let accessToken = null;
let expiresOn = null;

/**
 * Sets the account information.
 * @param {msal.AccountInfo} account - The account information.
 */
function setAccount(account) {
  accountId = account.homeAccountId;
  username = account.username;
  // Call method to "welcome" user
  // Track user login event
  datadogRum.setUser({
    id: accountId,
    name: username,
    email: username,
    plan: "Trial",
  });
}

/**
 * Selects the account to be used.
 */
export function selectAccount() {
  /**
   * See here for more information on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = msalInstance.getAllAccounts();

  if (currentAccounts.length > 1) {
    /**
     * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
     * is cached as a new account, which results in more than one account in the cache. Here we make
     * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
     * as this is the default flow the user initially signed-in with.
     */
    const accounts = currentAccounts.filter(
      (account) =>
        account.homeAccountId
          .toUpperCase()
          .includes(b2cPolicies.names.signUpSignIn.toUpperCase()) &&
        account.idTokenClaims?.iss
          ?.toUpperCase()
          .includes(b2cPolicies.authorityDomain.toUpperCase()) &&
        account.idTokenClaims.aud === msalConfig.auth.clientId
    );

    if (accounts.length > 1) {
      // localAccountId identifies the entity for which the token asserts information.
      if (
        accounts.every(
          (account) => account.localAccountId === accounts[0].localAccountId
        )
      ) {
        // All accounts belong to the same user
        setAccount(accounts[0]);
      } else {
        // Multiple users detected. Logout all to be safe.
        signOut();
      }
    } else if (accounts.length === 1) {
      setAccount(accounts[0]);
    }
  } else if (currentAccounts.length === 1) {
    setAccount(currentAccounts[0]);
  }
}

// in case of page refresh
selectAccount();

/**
 * Handles the authentication response.
 * @param {msal.AuthenticationResult} response - The authentication response.
 */
function handleResponse(response) {
  /**
   * To see the full list of response object properties, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
   */

  if (response !== null) {
    setAccount(response.account);
  } else {
    selectAccount();
  }
}

/**
 * Initiates the sign-in process.
 */
export function signIn() {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  msalInstance.loginRedirect(loginRequest);
}


/**
 * Initiates the sign-out process.
 */
export function signOut() {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  datadogRum.stopSession();
  const logoutRequest = {
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
  };

  msalInstance.logoutRedirect(logoutRequest).catch((error) => {
    console.error("error during logout", error);
  });
}

/**
 * Acquires a token using redirect.
 * @param {msal.SilentRequest} request - The token request object.
 * @returns {Promise<string>} A promise that resolves to an access token.
 */
export async function getTokenRedirect(request) {

  if(!isMsalInitialized) await initializeMsalInstance();
  if(!accountId) selectAccount();

  const expiresOnDate = new Date(expiresOn);
  const expiresOnUnix = expiresOnDate.getTime();

  if (expiresOnUnix > new Date().getTime()) {
    return accessToken;
  }

  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  const accounts = msalInstance.getAllAccounts();

  accounts.forEach((account) => {
  });

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  if (!msalInstance.getAccount(accountId))
    console.error("No account object found!");
  request.account = msalInstance.getAccount(accountId);

  /**
   *
   */
  return msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === "") {
        console.log(
          "Silent token acquisition returns null. Initiating token acquisition through redirect"
        );
        throw new msal.InteractionRequiredAuthError();
      } else {
        console.log("access_token acquired at: " + new Date().toString());

        accessToken = response.accessToken;
        expiresOn = response.expiresOn;
        return accessToken;
      }
    })
    .catch((error) => {
      console.log(
        "Silent token acquisition fails. Acquiring token using redirect. \n",
        error
      );
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        authConfig
          .renewToken()
          .then((response) => {
            if (!response.accessToken || response.accessToken === "") {
              console.log(
                "Silent token acquisition returns null. Initiating token acquisition through redirect"
              );
              signOut();
            }

            accessToken = response.accessToken;
            expiresOn = response.expiresOn;
            return accessToken;
          })
          .catch((error) => {
            console.log(error);
            signOut();
          });
      } else {
        console.log(error);
      }
    });
}

/**
 * To initiate a B2C user-flow, simply make a login request using
 * the full authority string of that user-flow e.g.
 * https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_edit_profile_v2
 */
export function editProfile() {
  const editProfileRequest = b2cPolicies.authorities.editProfile;
  editProfileRequest.loginHint = msalInstance.getAccount(accountId).username;

  msalInstance.loginRedirect(editProfileRequest);
}
