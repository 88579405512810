<script>
export const ButtonUITypeEnum = {
  iconButton: "icon-button",
  mainButton: "main-button",
  secondaryButton: "secondary-button",
  transparentButton: "transparent-button",
  dangerButton: "danger-button",
};

export const ButtonBehaviourEnum = {
  routerLink: "routerLink",
  onClick: "onClick",
};

export default {
  name: "BaseButton",
  props: {
    onClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    routerLinkTo: {
      type: Object,
      required: false,
      default: {},
    },
    text: {
      type: String,
      required: true,
    },
    textSize: {
      type: String,
      required: false,
      default: "text-sm",
    },
    buttonUIType: {
      type: ButtonUITypeEnum,
      required: false,
      default: ButtonUITypeEnum.iconButton,
    },
    buttonBehaviour: {
      type: ButtonBehaviourEnum,
      required: false,
      default: ButtonBehaviourEnum.onClick,
    },
    isIconAfterText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isBehaviourRouterLink() {
      return this.buttonBehaviour === ButtonBehaviourEnum.routerLink;
    },
    ButtonUITypeEnum() {
      return ButtonUITypeEnum;
    },
  },
};
</script>

<template>
  <template v-if="isBehaviourRouterLink">
    <router-link
      :to="routerLinkTo"
      class="flex gap-x-1 items-center transition-all duration-200 ease-in-out"
      :class="`${buttonUIType} ${
        isIconAfterText ? 'flex-row-reverse' : 'flex-row'
      }`"
    >
      <slot />
      <span :class="textSize" class="w-full whitespace-nowrap">{{ text }}</span>
    </router-link>
  </template>
  <template v-else>
    <button
      class="flex gap-x-1 items-center transition-all duration-200 ease-in-out"
      :class="`${buttonUIType} ${
        isIconAfterText ? 'flex-row-reverse' : 'flex-row'
      }`"
      @click="onClick"
    >
      <slot />
      <span :class="textSize" class="w-full whitespace-nowrap">{{ text }}</span>
    </button>
  </template>
</template>

<style scoped></style>
