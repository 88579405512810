<template>
  <footer class="bottom:0 mt-auto">
    <div
      class="bg-gray-200 text-gray-700 text-center p-4 mt-8 flex items-center justify-center w-full"
    >
      <router-link
        class="mx-10"
        style="font-family: agenda-light"
        to="/privacy"
      >
        <button>Privacy Policy</button>
      </router-link>
      <!-- download from app store options -->
      <div class="mx-10">
        <a
          href="https://apps.apple.com/ie/app/kinetikiq/id6464038934"
          target="_blank"
        >
          <DownloadOnAppStoreIcon />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import DownloadOnAppStoreIcon from "@/components/Icons/DownloadOnAppStoreIcon.vue";

export default {
  name: "Footer-component",
  components: { DownloadOnAppStoreIcon },
};
</script>

<style scoped>
button {
  text-decoration: underline;
}
</style>
