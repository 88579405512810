import featureFlagService from "@/utils/FeatureFlagService";

export default {
  install(app) {
    const featureFlags = {};

    // Add a global $features object to your Vue app
    app.config.globalProperties.$features = featureFlags;

    // Provide feature flags to all components
    app.provide("features", featureFlags);

    // Method to load all feature flags
    app.config.globalProperties.$loadFeatures = async function (flagNames) {
      for (const name of flagNames) {
        featureFlags[name] = await featureFlagService.getFeatureFlag(name);
      }
      return featureFlags;
    };
  },
};
