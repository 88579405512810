<template>
  <!--
  <div
    :style="'background-color: hsl(' + hashNameToHue + ', 100%, 80%)'"
    class="rounded-full grid delay-500 transition-all duration-500 ease-in-out"
  >
  -->
  <div
    :style="{
      width: `${size}px`,
      height: `${size}px`,
      minWidth: `${size}px`,
      minHeight: `${size}px`,
      maxWidth: `${size}px`,
      maxHeight: `${size}px`,
      backgroundColor: 'lightgray',
    }"
    class="rounded-full grid place-self-center justify-center items-center"
  >
    <!-- Currently not implemented - 11/01/2024 -->

    <!-- Display Camera if no name input 
  <img v-if="initials == ''"
          alt="logo"
          class=" w-12 h-12"
          src="../assets/cameraIcon.png"
        />
  -->
    <!-- Otherwise display initials (Just needs a v-else to work with above)-->
    <div class="sm:text-4xl text-3xl place-self-center">{{ initials }}</div>
  </div>
</template>
<script>
export default {
  name: "DefaultProfilePicture",
  props: {
    name: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    initials() {
      if (this.name === undefined) {
        return "";
      }
      return this.name
        .split(" ")
        .map((str) => str[0])
        .filter(
          (value, index, array) => index === 0 || index === array.length - 1
        )
        .join("")
        .toUpperCase();
    },
    hashNameToHue() {
      if (this.name === undefined) {
        return 0;
      }
      let hash = 0;
      for (let i = 0; i < this.name.length; i++) {
        hash = this.name.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash % 360;
    },
  },
};
</script>
