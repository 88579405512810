import { AppConfigurationClient } from "@azure/app-configuration";

const connectionString = process.env.VUE_APP_AZURE_APP_CONFIG_CONNECTION_STRING;
const client = new AppConfigurationClient(connectionString);

export default {
  async getFeatureFlag(flagName) {
    const env = process.env.VUE_APP_FEATURE_FLAG_LABEL || "production";
    const featureLabel = env === "production" ? "" : env;

    try {
      const setting = await client.getConfigurationSetting({
        key: `.appconfig.featureflag/${flagName}`,
        label: featureLabel,
      });

      const featureFlag = JSON.parse(setting.value);
      return featureFlag.enabled;
    } catch (error) {
      console.error(`Error getting feature flag ${flagName}:`, error);
      return false; // Default to disabled if there's an error
    }
  },
};
