<template>
  <div class="min-h-screen flex flex-col bg-[var(--main-background-colour)]">
    <headerComponent />
    <ReturnButton />
    <!-- Add some padding on mobile for the return button -->
    <div class="max-lg:mt-[30px] max-lg:min-h-[1px] max-lg:min-w-full"></div>
    <router-view />
    <footerComponent class="bottom-0"></footerComponent>
  </div>
</template>

<script>
import headerComponent from "./components/Header.vue";
import footerComponent from "@/components/Footer.vue";
import { useIsAuthenticated } from "@/msal/useIsAuthenticated";
import { useDataStore } from "@/stores/DataStore";
import { msalInstance, tokenRequest } from "@/msal/authConfig";
import { updateTokenClaims } from "@/utils/UserCategoriesUtils";
import ReturnButton from "@/components/ReturnButton.vue";

export default {
  name: "App",
  components: {
    ReturnButton,
    footerComponent,
    headerComponent,
  },
  setup() {
    const dataStore = useDataStore();
    if (!msalInstance.getActiveAccount())
      return {
        isAuthenticated: useIsAuthenticated(),
        dataStore: dataStore,
      };
    msalInstance.acquireTokenSilent(tokenRequest).then(() => {
      dataStore.negotiateSignalRConnection();
      updateTokenClaims();
    });
    return {
      isAuthenticated: useIsAuthenticated(),
      dataStore: dataStore,
    };
  },
};
</script>

<style>
body {
  margin: 0;
}

/*noinspection CssUnusedSymbol*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}
</style>
